 
  .modal_content {
    /* content: inherit; */
    width: 20%;
    height: 50%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 0; */
    border: none;
    position: fixed;
    z-index: 999 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 180px;
    max-height: 300px;

  }

  
  .modal_header {
    background-color: blue;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%; 
  }
  
  .modal_body {
    background-color: white;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
  }
  
  .modalButton {
    background-color: blue;
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .parragraph_style_sent_email_model{
    color: black;
    font-size: 1em;
    padding: 1em auto;
    /* padding: 1.5em 0; */
  }

  .tittle_style_sent_email_model{
    color: black;
    font-size: 1.5em;
    font-family: 'Urbanist', sans-serif;
    padding: 1.5em 0;
  }

  .button_style_sent_email_model{
    background-color: blue;
    color: white,;
    border: none;
    border-radius: 75px;
    padding: 0.5em 1.5em;
    cursor: pointer;
    font-family: 'Urbanist', sans-serif;
    font-size: 1em;
    margin: 1em;
    
  }

  .image_style_sent_email_model{
    max-width: 100%;
    max-height: 100%;
  }


  @media (max-width: 768px) {
    .modal_content {
      width: 40%;
      height: 40%;
    }

  
    .parragraph_style_sent_email_model{
      font-size: 0.8em;
      padding: 0.6em auto;
    }
  
    .tittle_style_sent_email_model{
      font-size: 1.2em;
      padding: 1em 0;
    }
}


@media (max-width: 400px) {
  .modal_content {
    width: 50%;
    height: 50%;
    min-height: 150px;
    max-height: 200px;
  }
  .modal_header {
    width: 100%;
    height: 40%; 
  }
  
  .modal_body {
    width: 100%;
    height: 100%;
  }


  .parragraph_style_sent_email_model{
    font-size: 0.8em;
    padding: 0.6em auto;
  }

  .tittle_style_sent_email_model{
    font-size: 1em;
    padding: 1em 0;
  }

  .image_style_sent_email_model{
    max-width: 100%;
    max-height: 100%;
  }
  .button_style_sent_email_model{
    margin: 0.5em;
    
  }

}