.header_info_style_contact{
    width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 2em;
    line-height: 1.3em;
}

.contact_features_style{
    margin-top: 6em!important;
}

.text_style_feature_contact{
    padding-top: 1em;
}

.title_info_features_contact{
    font-size: 1.7em;
    font-weight: 600;
    line-height: 117.5%;
}

.paragraph_text_style_contact{
    text-align: center;
    font-size: 1em;
    font-weight: 300;
    height: 20vh;
    max-height: 100px;
}

.link_style_feature_contact{
    display: inline-block;
}

.card_container_style_contact{
    padding: 0 2em!important;
}

.contact_information_container_style{
    margin-top: 10em !important;
    margin-bottom: 1em !important;
}

.icon_feature_contact{
    height:60px ;
}

/* tablet */
@media (max-width: 768px) {
    
    .header_info_style_contact{
        width: 80%;
        font-size: 1.8em;
    }
    
     .contact_features_style{
        margin-top: 3em!important;
    }

    .card_container_style_contact{
        padding: 2em 2em!important;
    }
     
    .contact_information_container_style{
        margin-top: 6em !important;
        margin-bottom: 6em !important;
    }

    .icon_feature_contact{
        height: 45px;
    }

    .title_info_features_contact{
        font-size: 1.4em;
    }

    .paragraph_text_style_contact{
        height: 10vh;
        max-height: 80px;
    }
}

/* phone */
@media (max-width: 400px) {
    
     .header_info_style_contact{
        width: 100%;
        font-size: 1.5em;
    }
    
    .card_container_style_contact{
        padding: 1em 0em!important;
    }
     

    .icon_feature_contact{
        height: 40px;
    }

    .title_info_features_contact{
        font-size: 1.2em;
    }

    .paragraph_text_style_contact{
       display: none;
    }
    
    .link_style_feature_contact{
        display: none;

        /* font-size: 0.8em !important; */
    }
    .contact_information_container_style{
        margin-bottom: 4em !important;
    }
}