.email_styles{
    color: #009CFF;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 121.5%; /* 33.478px */
}
.email_contact_styles{
    color: #FFF;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 117.5%; /* 25.901px */
    display: block;
    margin-top: 1.5em;
}
.image_contacto_email{
    max-width: "100%"; 
    height: "auto";
    width: 20vw;
    z-index: 1;
}
.contact_by_email_information_style{
  width: 70% !important;
  margin: 0 auto;
}

@media (max-width: 768px) {
    
    .email_styles {
      font-size: 1.8rem; /* Tamaño de letra reducido para dispositivos móviles */
    }
  
    .email_contact_styles {
      font-size: 1.2rem; /* Tamaño de letra reducido para dispositivos móviles */
    }
    .image_contacto_email{
        width: 45vw;
    }
  }

  @media (max-width: 768px) {
    
    .email_contact_styles {
      font-size: 1em; /* Tamaño de letra reducido para dispositivos móviles */
    }
    .image_contacto_email{
        width: 60vw;
        padding-top: 1em;
    }
  }