.body_background_mantenimiento{
    background-color: white;
    height: 100%;
    width: 100%;
   
  }

.button_contacto_maintenance_style{
    border-radius: 75px;
    padding: 0.7em 2.3em;
    font-weight: 700;
    font-size: 1em;
    font-family: 'Urbanist', sans-serif;
    border-color: transparent;
    border: 0px;
    background: var(--D2, linear-gradient(94deg, #48C6EF 0%, #006AE0 100%));
    backdrop-filter: blur(11px);
    cursor: pointer ;
}
/* .button_contacto_maintenance_style::after{
    
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0%;
    background-color: #006AE0;
    transition: height 0.3s ease;
    border-radius: 75px;
}
.button_contacto_maintenance_style:hover{
    background-color: #006AE0;
    border-radius: 75px;
}
.button_contacto_maintenance_style:hover::after{
    height: 100%;
    border-radius: 75px;
} */

.left_container_style_mantenimiento {
    padding-left: 10em !important;
}

.title_header_style_maintanence {
    color: #006DB3;
    font-family: Urbanist;
    font-size: 2.8em;
    font-style: normal;
    font-weight: 800;
    line-height: 1.3em; 
    width: 80%;
    margin-top: 2em ;
}

.subtitle_header_style_maintanence{
    color: #001E35;
    font-family: Urbanist;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 600;
    line-height: 121.5%; /* 29.16px */
    width: 60%;
    margin: 1em 0;
}

.image_mantenimiento{
    height: 80vh; 
    min-height: 500px;
    flex-shrink: 0;
}

.container_form_mantenimiento{
    margin-top: 2em;
}

.button_form_maintenance_style{
    border-radius: 0 15px 15px 0;
    background: #006AE0;
    padding: 15px 16px;
    justify-content: center;
    align-items: center;
    border: 0px;
    box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.07);
    cursor: pointer ;

}
.button_form_maintenance_style:hover{
    background: #013066;
}

.form_mantenimiento{
    background:transparent;
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 1;
} 

.animacion_texto_mantenimiento{
    text-align: center;
    font-family: Urbanist;
    font-size: 12.5em;
    font-style: normal;
    font-weight: 900;
    line-height: 102.5%; /* 220.537px */
    -webkit-text-stroke: 2px #006DB33D;
    color: transparent;
    margin: 0 auto;
    position: absolute;
    top: 350px;
}

.input_form_style_mantenimiento{
    border: none;
    border-radius: 15px 0 0 15px;
    color: black;
    padding-left: 10px;
    outline: none;
    color: #001E35;
    font-family: Urbanist;
    font-size: 0.9em;
    box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.07);
    padding: 15px 0;
    padding-left: 10px;
}

@keyframes animacion_texto_mantenimiento{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}

.animacion_texto_mantenimiento{
    animation: animacion_texto_mantenimiento 40s linear infinite;
}


@media (max-width: 768px) {
    .left_container_style_mantenimiento {
        padding-left: 2.5em !important;
    }
    
    .title_header_style_maintanence {
        font-size: 1.8em;
        width: 90%;
        margin-top: 3em;

    }
    .image_mantenimiento{
        height: 75%; 
    }

    .subtitle_header_style_maintanence{
        font-size: 1.1em;
        width: 80%;
    }

    .animacion_texto_mantenimiento{
        font-size: 6.5em;
        top: 350px;
    }
}


@media (max-width: 400px) {
    .left_container_style_mantenimiento {
        padding-left: 0.2em !important;
    }
    
    .title_header_style_maintanence {
        font-size: 1.7em;
        width: 90%;
        margin-top: 3em;
        z-index: 1;

    }
    .image_mantenimiento{
        height: 40vh; 
        min-height: 300px;
        z-index: 0;
        opacity: 0.7;
    }


    .subtitle_header_style_maintanence{
        font-size: 1em;
        width: 100%;
        padding-top: 3em;
    }

    .animacion_texto_mantenimiento{
        font-size: 2.8em;
        top: 400px;

    }

    .form_mantenimiento{
        width: 140%;
        
    }
    .container_form_mantenimiento{
        margin-top: 5em;
        
    }
    .input_form_style_mantenimiento{
        font-size: 0.9em;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
    }
    .button_form_maintenance_style{
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.07);
    }

}
