.circle {
    width: 107px;
    height: 107px;
    background-color: #009cff;
    border-radius: 100%;
    position: absolute;
  }
  
  .circle_1 {
    
    left: 22em; 
    top: 0em;
  }

  .circle_2 {
    width: 160px;
    height: 160px;
    background-color: #004673;
    top: 28em;
    left: 35em; 
  }
  
  .circle_3 {
    width: 297px;
    height: 297px;
    background-color: #006db3;
    
    top: 7em;
    left: 95em;
  }

  .circle_4 {
    
    top: 51em;
    left: 94.4vw;
    filter: blur(4.8em);
  }

  .circle_5 {
    filter: blur(6em);
    top:79.9em;
    left: -3.4vw;
  }

  .circle_6 {
    filter: blur(2.8em);
    top: 102.73em;
    left: 64em;
    z-index: 0;
  }

  .circle_7 {
    filter: blur(6em);
    top: 116.1em;
    left: 90.6vw;
  }

  .foto_binarios {
    position: absolute;
    top: 26.4em;
    left: 0em;
  }

 .foto_binarios_2 {
    top: 47em;
    left: 100em;
  }

/* tablet 768 */
@media (max-width: 768px) {
    
      .circle_1 {
        left: 22em; 
        top: 0em;
      }
    
      .circle_2 {
        top: 39em;
        left: 14em; 
      }
      
      .circle_3 {
        top: 14em;
        left: 35em;
      }
    
      .circle_4 {
        top: 51em;
        left: 94.4vw;
      }
    
      .circle_5 {
        top:79.9em;
        left: -3.4vw;
      }
    
      .circle_6 {
        top: 130em;
        left: 10em;
      }
    
      .circle_7 {
        top: 116.1em;
        left: 90.6vw;
      }
    
      .foto_binarios {
        top: 36em;
        left: -7em;
      }
    
     .foto_binarios_2 {
        top: 60em;
        left: 40em;
      }
  }

  /* phone */
  @media (max-width: 400px) {
    
    .circle_1 {
      left: 5em; 
      top: 0em;
    }
   
    .circle_2 {
      top: 39em;
      left: 14em; 
      width: 140px;
      height: 140px;
    }
    
    .circle_3 {
      top: 18em;
      left: 18em;
      width:200px;
      height: 200px;
    }
  
    .circle_4 {
      top: 65em;
      left: 94.4vw;
    } 
    
    .circle_6 {
      top:112em;
      left: 2em;
    }
    .foto_binarios {
      top: 38em;
      left: 0em;
      height: 190px;
    }
  
   .foto_binarios_2 {
      top: 90em;
      left: 20em;
      height: 200px;
    } 
}