@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap');

:root {
    --font-family: 'Urbanist', sans-serif;
    /* --gradient-text: linear-gradient();
    --gradient-bar: linear-gradient(); */

    --color-bg: #001E35;
    --color-footer: #001221;
    --color-blog:  #0000;
    --color-text:  #FFFFFF;
    --color-subtext:  #009CFF;
}

