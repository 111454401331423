.body_background_contact{
  background-color: #001E35;
  height: 100%;
  width: 100%;
  padding: 4em;
}


.layout_contact_page{
    padding:0;
}

.circle_background{
    z-index: 0;
    display: flex;
}

