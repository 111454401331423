@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;600;700&display=swap');

:root {
    --font-family: 'Urbanist', sans-serif;
    /* --gradient-text: linear-gradient();
    --gradient-bar: linear-gradient(); */

    --color-bg: #001E35;
    --color-footer: #001221;
    --color-blog:  #0000;
    --color-text:  #FFFFFF;
    --color-subtext:  #009CFF;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    color: aliceblue;
}

body {
    font-family: 'Urbanist', sans-serif;
    max-width: 100% !important;
    background: var(--color-bg);
    padding: 4em;
    /* poner el pading ara dispositivos moviles 2em */
   
}
body, html {
    position: relative;
    overflow-x: hidden !important;
    }


h2{
    font-size: 64px;
}

p{
    font-size: 20px;
}

@media (max-width: 768px) {
    
    body {
        padding: 2em;
    }
  
  }

  @media (max-width: 400px) {
    
    body {
        padding: 1em;
    }
  
  }
