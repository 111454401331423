.text_save_contact{
    color: #FFF;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 2em;
    line-height: 1.3em;
}


.button_save_contact_style{
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 75px;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 1.1em;
    font-family: 'Urbanist', sans-serif;
    border: 0px;

    background: var(--D1, linear-gradient(151deg, #89F7FE 0%, #66A6FF 100%));
    backdrop-filter: blur(11px);
    cursor: pointer ;
}

.download_container_style_contact{
  margin-top: 8em !important;
}

@media (max-width: 768px) {
    
    .text_save_contact {
      font-size: 1.6em!important; /* Tamaño de letra reducido para dispositivos móviles */
      width: 80%;
    }
    .download_container_style_contact{
      margin-top: 1em !important;
    }

    .button_save_contact_style{
      margin-top: 1em;
      margin-bottom: 4em;
      font-weight: 700;
      font-size: 1em;
  }
}

@media (max-width: 400px) {
    
  .text_save_contact {
    font-size: 1.5em!important; /* Tamaño de letra reducido para dispositivos móviles */
    width: 90%;
  }
  .download_container_style_contact{
    margin-top: 0.1em !important;
  }

  .button_save_contact_style{
    margin-top: 1em;
    margin-bottom: 5em;
    font-weight: 700;
    font-size: 1em;
  }
}