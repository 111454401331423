.container_form_contact{
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 60vh;
    margin: 0 auto;
    border-radius: 25px;
    background-color: rgba(0, 156, 255, 0.14);
    backdrop-filter: blur(25.5px); /* Valor de desenfoque adecuado */
    backdrop-filter: blur(25.5px); /* Propiedad específica de WebKit */
    justify-content: space-around;
    box-sizing: border-box;
    z-index: 1;
    align-items: center;
    padding: 4em;
    min-height: 450px;


}

.text_header_subtitle_form{
    width: 40%;
    font-style: normal;
    font-weight: 400;
    margin-top: -3.5em;
}

.text_header_form{
    color: #FFF;
    font-family: Urbanist;
    font-size: 4.5em;
    font-style: normal;
    font-weight: 700;
    line-height: 102.5%; /* 65.6px */
    width: 66%;
}

.button_send_contact_style{
    width: 101px;
    height: 38.069px;
    flex-shrink: 0;
    border-radius: 65.262px;
    border: 0.777px solid #FFF ;
    background: linear-gradient(94deg, #48C6EF 0%, #006AE0 100%);
    margin-top: 3em;
    font-family: 'Urbanist', sans-serif;
    font-weight: 700;
    font-size: 1em;
    cursor: pointer ;
}

.fields_style_form_contact{
    padding-bottom: 1.5em;
}

/* tablet */
@media (max-width: 768px) {
    
    .container_form_contact{
        width: 95%;
        min-height: 400px;
    
    
    }
    
    .text_header_subtitle_form{
        width: 80%;
        margin-top: 1em;  
        margin-bottom: 1em;
    }
    
    .text_header_form{
        font-size: 4em;
        font-style: normal;
        width: 100%;
    }
}

/* tablet */
@media (max-width: 400px) {

    .container_form_contact{
        width: 100%;
        min-height: 700px;
        height: 60vh;
        padding: 1.2em;
    }
    
    .text_header_subtitle_form{
        width: 80%;
        margin-top: 1em;  
        margin-bottom: 1em;
        font-size: 1em;
        margin: 1em auto;
    }
    
    .text_header_form{
        font-size: 2.7em;
        font-style: normal;
        width: 100%;
    }
   
    .fields_style_form_contact{
        padding-bottom:0;
    }
    .button_send_contact_style{
        width: 60%;
        min-width: 150px;
    }
}

